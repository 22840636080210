// from https://stackoverflow.com/a/57401891
// lighten / darken hexadecimal color by a certain amount
// @example
// adjustLightness('#ffffff', -20); => '#ebebeb'
// adjustLightness('#000000', 20); => '#141414'

const adjustLightness = (color, adjust) =>
  '#' +
  color
    .replace(/^#/, '')
    .replace(/../g, color =>
      (
        '0' +
        Math.min(255, Math.max(0, parseInt(color, 16) + adjust)).toString(16)
      ).slice(-2),
    );

export default adjustLightness;
