import React, { useEffect, useContext, useState } from 'react';
// import PropTypes from "prop-types";
import { ThemeContext } from 'ToolboxUtils/web/context/context';

import Upload from 'ToolboxComponents/commons/upload/upload';
import Label from 'ToolboxComponents/commons/upload/ui/label';
import SingleUploadImage from 'ToolboxComponents/commons/upload/ui/single-upload-image';

import styled from 'styled-components';
const UploadSecretImageWrapper = styled.div`
  .progress-bar-container {
    width: 100%;
    margin-bottom: 5px;

    &:before {
      content: '';
      display: block;
      width: 0;
      width: ${props =>
        props.initialProgress === true ? '0' : props.progress}%;
      height: 5px;
      background: ${props => props.color};
      transition: width 0.3s;
    }
  }
`;

const UploadSecretImage = props => {
  const { photoValue, handlePhoto } = props;

  const color = useContext(ThemeContext).state.color.primaryColor;
  const [completed, setCompleted] = useState(undefined);
  const [progress, setProgress] = useState(0);

  const onCompleteCallBack = info => {
    setCompleted(true);
    handlePhoto(info[0]);
  };
  const onProgressCallBack = value => {
    setProgress(value);
  };
  const resetUpload = () => {
    setCompleted(false);
    setProgress(0);
    handlePhoto('');
  };

  useEffect(() => {
    if (photoValue) {
      setCompleted(true);
    }
  }, [photoValue]);

  return (
    <UploadSecretImageWrapper
      className={`upload-secret-image`}
      progress={progress}
      color={color}
    >
      <div className={`progress-bar-container`}></div>
      <Upload
        key={`upload-secret-image`}
        className={`interface`}
        onComplete={onCompleteCallBack}
        onProgress={onProgressCallBack}
      >
        {(photoValue || (completed === true && progress > 0)) && (
          <div
            key={`upload-secret-image-delete-file`}
            className={`add-delete-file`}
            onClick={() => resetUpload()}
          >
            <div className={`icon icon-add-delete-file adn adn-close`}></div>
            <div className={`text`}>retirer cette photo</div>
          </div>
        )}
        {completed === false && progress > 0 && (
          <div
            key={`upload-secret-image-load-file`}
            className={`add-delete-file`}
          >
            <div className={`icon icon-add-delete-file adn adn-reload`}></div>
            <div className={`text`}>en cours de téléchargement...</div>
          </div>
        )}
        {completed === true ? (
          <SingleUploadImage
            key={`upload-image`}
            className={`single-upload-image`}
            photoValue={photoValue}
          />
        ) : (
          <div
            key={`upload-secret-image-preview-icon`}
            className={`icon icon-preview adn adn-image`}
          ></div>
        )}
        {!photoValue && progress === 0 && (
          <Label
            key={`upload-label`}
            clickToUpload={progress > 0 ? false : true}
          >
            <div className={`add-delete-file`}>
              <div className={`icon icon-add-delete-file adn adn-upload`}></div>
              <div className={`text`}>ajouter une photo</div>
            </div>
          </Label>
        )}
      </Upload>
    </UploadSecretImageWrapper>
  );
};

// UploadSecretImage.propTypes = {};

export default UploadSecretImage;
