import React from 'react';
// import PropTypes from "prop-types";
import styled from 'styled-components';
import Div100vh from 'react-div-100vh';

const SubmittedWrapper = styled.div``;

const Submitted = props => {
  const { onClose, userName, isMobile } = props;

  const content = () => (
    <SubmittedWrapper className={`submitted`}>
      <div className={`text`}>
        <div className={`title`}>Merci {userName}</div>
        <div className={`paragraph`}>
          Vous allez faire de nos visiteurs des privilégiés. Notre équipe vient
          d'être informée de votre secret et va le traiter.
        </div>
        <div className={`paragraph`}>
          A très vite pour chuchoter de nouveaux secrets locaux !
        </div>
      </div>
      <div className={`close-modal`}>
        <div onClick={() => onClose()} className={`button`}>
          fermer la fenêtre et continuer ma visite
        </div>
      </div>
    </SubmittedWrapper>
  );

  return (
    <>{isMobile === true ? <Div100vh>{content()}</Div100vh> : content()}</>
  );
};

// Submitted.propTypes = {};

export default Submitted;
