import styled from 'styled-components';
import { isMobileBreakpoint } from 'ToolboxUtils/web/is-mobile-view';
import adjustLightness from 'ToolboxUtils/web/style/adjust-lightness';

const ModalSecretWrapper = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  overflow-y: auto;

  /* Hide scrollbar for Chrome, Safari and Opera */
  &::-webkit-scrollbar {
    display: none;
  }
  /*firefox*/
  scrollbar-width: none;

  /* Hide scrollbar for IE and Edge */
  -ms-overflow-style: none;

  z-index: 30000000;

  .modal-container {
    position: relative;
  }

  .modal-close {
    color: ${props => props.theme.color.primaryColor};
    border: 1px solid ${props => props.theme.color.primaryColor};
    height: 26px;
    width: 26px;
    border-radius: 50%;
    font-size: 24px;
    position: absolute;
    right: 10px;
    top: 10px;
    line-height: 24px;

    &:hover {
      cursor: pointer;
    }
  }

  .left-pane {
    background: ${props => props.theme.color.tertiaryColor};
    padding: 50px 20px 20px;
  }

  .right-pane {
    background: ${props =>
      props.showTutorial === true ? props.theme.color.primaryColor : 'white'};
    padding: 20px;
  }

  .title {
    font-size: ${props =>
      `${(16 * props.theme.font.primaryFont.scaling) / 100}px`};
    color: ${props => props.theme.color.primaryColor};
    font-weight: ${props =>
      parseInt(props.theme.font.primaryFont.weight) + 300};
    margin-bottom: 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .input {
    border: 2px solid ${props => props.theme.color.primaryColor};
    width: 100%;
    padding: 5px;
    margin-bottom: 10px;
    &::placeholder {
      color: ${props => props.theme.color.quaternaryColor};
    }

    &.error {
      background: rgba(255, 0, 0, 0.3);
    }
  }

  .separator {
    display: none;
    border-top: 2px solid ${props => props.theme.color.primaryColor};
    padding-bottom: 10px;
  }

  .map {
  }

  .textarea {
    min-height: 240px;
  }

  .nb-characters {
    font-size: ${props =>
      `${(15 * props.theme.font.primaryFont.scaling) / 100}px`};
    color: ${props => props.theme.color.quaternaryColor};
    text-align: right;
    margin-top: -13px;
    margin-bottom: 10px;
  }

  .title-small {
    font-size: ${props =>
      `${(13 * props.theme.font.primaryFont.scaling) / 100}px`};
    color: ${props => props.theme.color.primaryColor};
  }

  .upload-secret-image {
    width: 100%;
    margin-top: 5px;
  }

  .upload {
    display: flex;
    flex-flow: wrap;
    margin-bottom: 30px;

    .title {
      flex: 0 0 auto;
      margin-bottom: 0;
      margin-right: 5px;
      line-height: 19px;
    }

    .interface {
      flex: 0 0 100%;
      display: flex;
      flex-flow: row-reverse;
      justify-content: flex-end;
      align-items: flex-end;

      .icon-preview {
        font-size: 80px;
        color: ${props => props.theme.color.primaryColor};
      }

      .add-delete-file {
        display: flex;
        margin-bottom: 12px;
        margin-left: 10px;

        &:hover {
          cursor: pointer;
        }

        .input-file {
          width: 1px;
          height: 1px;
          opacity: 0;
          position: fixed;
          top: -1px;
          left: -1px;
        }

        .icon-add-delete-file {
          font-size: 20px;
          color: ${props => props.theme.color.primaryColor};
        }
        .text {
          font-size: ${props =>
            `${(13 * props.theme.font.primaryFont.scaling) / 100}px`};
        }
      }
      .single-upload-image {
        width: 80px;
        height: 85px;
      }
    }
  }

  .button {
    user-select: none;
    color: ${props => props.theme.color.primaryColor};
    background: white;
    padding: 5px 20px;
    border: 2px solid ${props => props.theme.color.primaryColor};
    border-radius: 8px;
    text-align: center;
    transition: all 0.1s;
    cursor: pointer;
    color: white;
    background: ${props => props.theme.color.primaryColor};

    &.disabled {
      cursor: default;
      color: ${props => props.theme.color.primaryColor};
      border-color: ${props => props.theme.color.primaryColor};
      background: white;
    }

    // &:hover:not(.disabled) {
    //   cursor: pointer;
    //   color: white;
    //   background: ${props => props.theme.color.primaryColor};
    // }
  }

  .image-location {
    width: 100%;
    height: 230px;
    object-fit: cover;
    margin-bottom: 10px;
  }

  .title-location {
    .title {
      margin-bottom: 0;
    }
  }

  .first-secret {
    height: 100%;
    display: flex;
    flex-flow: column;

    .title {
      color: white;
      text-align: center;

      &:not(.title-light) {
        margin: 0;
      }
    }
  }

  .title-light {
    font-weight: ${props => parseInt(props.theme.font.primaryFont.weight)};
  }

  .first-secret-slider {
    margin-bottom: 10px;
    background: ${props => adjustLightness(props.theme.color.primaryColor, 30)};

    .swiper-pagination {
      top: -10px;
      margin: 0;
    }
  }

  .button-first-secret {
    transition: all 0.1s;

    &:hover {
      box-shadow: inset 0 0 0 1px white;
    }
  }

  .submitted {
    background: white;
    padding: 30px 20px 20px;
    // min-height: 100vh;
    height: {props => props.isMobile === true ? '100%' : ''};
    display: flex;
    flex-flow: column;
    justify-content: space-between;
    height: 100%;
    height: 100%;
    width: 100%;

    .title {
      text-align: center;
    }

    .paragraph {
      margin-bottom: 10px;
    }

    .close-modal {
      background: url('https://images.unsplash.com/photo-1499744937866-d7e566a20a61?crop=entropy&cs=tinysrgb&fit=crop&fm=jpg&h=600&ixid=eyJhcHBfaWQiOjF9&ixlib=rb-1.2.1&q=80&w=800');
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
      flex: 1 0 auto;
      display: flex;
      align-items: flex-end;
      justify-content: center;
    }

    .button {
      margin-bottom: 10px;
    }
  }

  .product-photo {
    height: 230px;
    margin-bottom: 20px;

    img {
      object-fit: cover;
      height: 100%;
      width: 100%;
    }
  }

  .product-title {
    color: ${props => props.theme.color.primaryColor};
    font-size: 16px;
    font-weight: bold;
  }

  .product-city {
    color: ${props => props.theme.color.primaryColor};
    font-size: 14px;
  }

  @media (min-width: ${isMobileBreakpoint}px) {
    display: flex;
    background: rgba(0, 0, 0, 0.25);
    justify-content: center;
    align-items: center;

    .modal-container {
      display: flex;
      min-height: 585px;
      width: 900px;
      background: white;
    }

    .modal-close {
      right: -13px;
      top: -13px;
      background: white;
    }

    .left-pane {
      width: 300px;
      padding: 20px;
    }

    .right-pane {
      width: 600px;
      min-height: 585px;
    }

    .title {
    }

    .input {
    }

    .separator {
      display: block;
    }

    .map {
    }

    .textarea {
    }

    .nb-characters {
    }

    .title-small {
    }

    .upload {
      margin-bottom: 10px;
    }

    .button {
    }

    .first-secret-slider {
      .swiper-pagination {
        top: -40px;
      }
    }

    .submitted {
      min-height: 100%;
      height: auto;
    }
  }
`;

export default ModalSecretWrapper;
