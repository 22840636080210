import React, { useEffect, useState, useContext } from 'react';
import PropTypes from 'prop-types';
import {
  ThemeContext,
  AccountContext,
  GeolocationContext,
  LanguageContext,
  WhiteLabelContext
} from 'ToolboxUtils/web/context/context';
import isMobileView from 'ToolboxUtils/web/is-mobile-view';
import ModalSecretWrapper from 'ToolboxComponents/webapp/modals/modal-secret/modal-secret.style';

import SecretDetails from 'ToolboxComponents/webapp/modals/modal-secret/components/secret-details';
import PlacesAutocomplete from 'ToolboxComponents/commons/places-autocomplete/places-autocomplete';
import FirstSecret from 'ToolboxComponents/webapp/modals/modal-secret/components/first-secret';
import Submitted from 'ToolboxComponents/webapp/modals/modal-secret/components/submitted';
import singleSpacer from 'ToolboxUtils/web/formats/single-spacer/single-spacer';
import smartTrim from 'ToolboxUtils/web/formats/smart-trim/smart-trim';

//timeout
let onClickSubmitTimeout;

const ModalAddSecret = props => {
  const {
    secret,
    product,
    onSubmit,
    onClose,
    isSecretSaved,
    errorOnSecretSave,
  } = props;

  const [isMobile, setIsMobile] = useState(null);
  useEffect(() => {
    setIsMobile(isMobileView());
  }, [isMobile]);

  const theme = useContext(ThemeContext).state;
  const whiteLabel = useContext(WhiteLabelContext).state;
  const [account] = useContext(AccountContext).usePath();
  const [geolocation] = useContext(GeolocationContext).usePath();
  const [language] = useContext(LanguageContext).usePath();
  const showTutorial = account.showTutorial;
  const userName = account.firstName;
  const userLocation = geolocation && geolocation.isGeolocated ? geolocation.coordinates : undefined;
  const [leftPaneState, setLeftPaneState] = useState();
  const [rightPaneState, setRightPaneState] = useState();

  const [idValue, setIdValue] = useState(undefined);
  const [titleValue, setTitleValue] = useState({});
  const [textareaValue, setTextareaValue] = useState({});
  const [locationValue, setSearchValue] = useState('');
  const [photoValue, setPhotoValue] = useState('');
  const [productIdValue, setProductIdValue] = useState(undefined);
  const [productTitleValue, setProductTitleValue] = useState('');
  const [productCityValue, setProductCityValue] = useState('');
  const [productCityCodeValue, setProductCityCodeValue] = useState('');
  const [productLatitudeValue, setProductLatitudeValue] = useState('');
  const [productLongitudeValue, setProductLongitudeValue] = useState('');
  const [productPhotoValue, setProductPhotoValue] = useState('');
  const [coordinatesValue, setCoordinatesValue] = useState([]);
  const [submitNotValidMessage, setSubmitNotValidText] = useState('');
  const [onButtonSubmitClicked, setOnButtonSubmitClicked] = useState(false);

  const [hasClickedSubmit, setHasClickedSubmit] = useState(false);
  const [hasSecret, setHasSecret] = useState(false);
  const [hasProduct, setHasProduct] = useState(false);

  const [secretSubmitted, setSecretSubmitted] = useState(false);

  //bitwise error check
  const [submitValid, setSubmitValid] = useState(false);
  const [textareaValid, setTextareaValid] = useState(false);
  const [locationValid, setSearchValid] = useState(false);
  const textareaBit = 1;
  const coordinatesBit = 1 << 1;

  const handleTitle = (value, language) => {
    const text = singleSpacer(smartTrim(value));
    setTitleValue({...titleValue, [language]: text});
  };
  const handleTextarea = (value, language) => {
    const text = singleSpacer(smartTrim(value));
    setTextareaValue({...textareaValue, [language]: text});
  };
  const handleSearch = value => {
    const text = singleSpacer(smartTrim(value));
    setSearchValue(text);
  };
  const handlePhoto = value => {
    const text = singleSpacer(smartTrim(value));
    setPhotoValue(text);
  };
  const handleCoordinates = value => {
    const coordinates = [{ latitude: value.coordinates.lat, longitude: value.coordinates.lng }];
    setCoordinatesValue(coordinates);
    setProductLatitudeValue(value.coordinates.lat);
    setProductLongitudeValue(value.coordinates.lng);
  };
  const handleCityCode = cityCode => setProductCityCodeValue(cityCode);

  const handleSubmit = () => {
    setHasClickedSubmit(true);
    setOnButtonSubmitClicked(true);
    clearTimeout(onClickSubmitTimeout);
    onClickSubmitTimeout = setTimeout(
      () => setOnButtonSubmitClicked(false),
      6000,
    );
    if (submitValid === true) {
      let payload = {
        secret: {
          id: idValue,
          title: titleValue,
          description: textareaValue,
          location: locationValue,
          photo: photoValue,
          cityCode: productCityCodeValue,
          latitude: productLatitudeValue,
          longitude: productLongitudeValue,
        },
        product: {
          productId: productIdValue,
          title: productTitleValue,
          city: productCityValue,
          photo: productPhotoValue,
        },
      };
      if (productIdValue !== undefined) {
        payload.secret.productId = productIdValue;
      }

      try {
        if (onSubmit !== undefined) {
          onSubmit(payload);
        }
      } catch (error) {
        throw error;
      }
      setSecretSubmitted(true);
    }
  };

  const handleClose = () => onClose();

  useEffect(() => {
    if (secret !== undefined) {
      setIdValue(secret.id);
      setTitleValue(secret.title);
      setTextareaValue(secret.description);
      setPhotoValue(secret.photo);
      setHasSecret(true);
      if (secret.location && secret.latitude) {
        setSearchValue(secret.location);
        setCoordinatesValue([{latitude: secret.latitude, longitude: secret.longitude}]);
        setSearchValid(true);
        setProductLatitudeValue(secret.latitude);
        setProductLongitudeValue(secret.longitude);
      }
    }

    if (product !== undefined) {
      setProductIdValue(product.id);
      setProductTitleValue(product.title);
      setProductCityValue(product.city);
      setProductCityCodeValue(product.cityCode);
      setProductLatitudeValue(product.latitude);
      setProductLongitudeValue(product.longitude);
      setProductPhotoValue(product.genericPhotos[0]);
      setHasProduct(true);
    }

    // block body scroll when modal is open
    document.querySelector('body').style.overflow = 'hidden';
    return () => {
      document.querySelector('body').style.overflow = 'auto';
    };

    // eslint-disable-next-line
  }, []);

  // validation logic
  useEffect(() => {
    let totalBit = 0;
    const requiredBit =
      hasProduct === true ? textareaBit : textareaBit | coordinatesBit;

    if ((textareaValue['fr'] || textareaValue['en'] || []).length >= 100) {
      totalBit = totalBit | textareaBit;
      setTextareaValid(true);
    } else {
      setTextareaValid(false);
      setSubmitNotValidText(
          whiteLabel.textualContent.page.secret.modalMandatoryDetail,
      );
    }

    if (coordinatesValue.length > 0) {
      totalBit = totalBit | coordinatesBit;
      setSearchValid(true);
    } else {
      setSearchValid(false);
    }

    if (totalBit === requiredBit) {
      setSubmitValid(true);
      setSubmitNotValidText('');
    } else {
      setSubmitValid(false);
      if (!(totalBit & coordinatesBit) && typeof product === 'undefined') {
        setSubmitNotValidText(
          whiteLabel.textualContent.page.secret.modalMandatoryPlace,
        );
      }
    }
    // eslint-disable-next-line
  }, [titleValue, textareaValue, locationValue]);

  //modal display switch
  useEffect(() => {
    if (hasProduct === true) {
      setLeftPaneState('product');
    } else {
      setLeftPaneState('');
    }

    if (showTutorial === true) {
      setRightPaneState('tutorial');
    } else {
      setRightPaneState('');
    }
  }, [hasProduct, showTutorial]);

  return (
    <ModalSecretWrapper
      isMobile={isMobile}
      onClick={event =>
        event.target.classList.contains('modal-secret--container') &&
        handleClose()
      }
      className={`modal-secret--container`}
      theme={theme}
    >
      <div className={`modal-container`}>
        <div
          onClick={() => onClose()}
          className={`icon adn adn-close modal-close`}
        />
        {isSecretSaved === true && secretSubmitted === true ? (
          <Submitted
            onClose={onClose}
            userName={userName}
            isMobile={isMobile}
          />
        ) : (
          <>
            <div className={`left-pane`}>
              {{
                product: (
                  <>
                    <div className={`title`}>
                      {whiteLabel.textualContent.page.secret.modalAddSecret}
                    </div>
                    <div className={`product-photo`}>
                      <img src={productPhotoValue} alt={``} />
                    </div>
                    <div className={`product-title`}>{productTitleValue}</div>
                    <div className={`product-city`}>à {productCityValue}</div>
                  </>
                ),
              }[leftPaneState] || (
                <PlacesAutocomplete
                  hasClickedSubmit={hasClickedSubmit}
                  locationValue={locationValue}
                  coordinatesValue={coordinatesValue}
                  handleSearch={handleSearch}
                  handleCoordinates={handleCoordinates}
                  handleCityCode={handleCityCode}
                  locationValid={locationValid}
                  userLocation={userLocation}
                />
              )}
            </div>
            <div className={`right-pane`}>
              {{
                tutorial: <FirstSecret />,
              }[rightPaneState] || (
                <SecretDetails
                  hasSecret={hasSecret}
                  hasClickedSubmit={hasClickedSubmit}
                  handleSubmit={handleSubmit}
                  titleValue={titleValue}
                  handleTitle={(value, language) => handleTitle(value, language)}
                  textareaValue={textareaValue}
                  handleTextarea={(value, language) => handleTextarea(value, language)}
                  textareaValid={textareaValid}
                  photoValue={photoValue}
                  handlePhoto={value => handlePhoto(value)}
                  submitValid={submitValid}
                  errorOnSecretSave={errorOnSecretSave}
                  submitNotValidMessage={submitNotValidMessage}
                  onButtonSubmitClicked={onButtonSubmitClicked}
                />
              )}
            </div>
          </>
        )}
      </div>
    </ModalSecretWrapper>
  );
};

ModalAddSecret.propTypes = {
  secret: PropTypes.object,
  product: PropTypes.object,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default ModalAddSecret;
