import React, { useState, useContext } from "react";
import styled from "styled-components";
import Select, { components } from "react-select";
import { LanguageContext, ThemeContext, WhiteLabelContext, WebappContext } from 'ToolboxUtils/web/context/context';
import isMobileView from "ToolboxUtils/web/is-mobile-view";
import { mdiWindows } from "@mdi/js";



const LanguagesWrapper = styled.div`
.dropdown-toggle {
  border: none;
  color: #fff;
  font-size: 0.88rem;
  line-height: 1em;
  padding: 2px 8px;
  text-transform: uppercase;
  background: ${props => props.theme.color.primaryColor};
  outline: none;
}

 .dropdown-menu {
  border: 1px solid grey;
  border-radius: 0;
  min-width: auto;
  padding: 0;
  width: 100%;
}

 .dropdown-item {
  color: grey;
  font-size: 0.88rem;
  padding: 1px 6px;
  outline: none;
}

 .dropdown-item.active,
 .dropdown-item:hover {
  color: ${props => props.theme.color.primaryColor};
  background-color: inherit;
  font-weight: 600;
}

 .dropdown-item.active:after,
 .dropdown-item:hover:after {
  font-family: 'agitateurs' !important;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  content: "\\ea39";
  font-size: 0.8rem;
  font-weight: normal;
  margin-left: 0.5em;
}
 
`;

const SelectLanguages = props => {
  const theme = useContext(ThemeContext).state; 
  const whiteLabel = useContext(WhiteLabelContext).state; 
  const [toggle, setToggle] = useState(false);

  return (
    <LanguagesWrapper theme={theme} style={{marginRight:'0'}}>
      <div className="dropdown">
        <button onClick={() => setToggle(!toggle)} className="dropdown-toggle" type="button" id="dropdownMenuButton" style={{height: '24px', textTransform: 'uppercase'}} data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">{props.language}</button>
        <div className="dropdown-menu" aria-labelledby="dropdownMenuButton" style={{display: toggle? 'block':'none'}}>
          {
            whiteLabel.languages.map(lang => 
              <button type="button" key={"selectLanguage" + lang} onClick={() => {props.onSelectLanguage(lang); setToggle(!toggle)}} className={"dropdown-item" + (props.language === lang ? ' active' : '')}>{lang.toUpperCase()}</button>             
            )
          }
        </div>
      </div>

    </LanguagesWrapper>
  );
};

export default SelectLanguages;
