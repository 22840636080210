import React, { useContext, useEffect, useState } from 'react';
import { ThemeContext, WhiteLabelContext } from 'ToolboxUtils/web/context/context';
import { ReactOsmGeocoding } from '@paraboly/react-osm-geocoding';
import '@paraboly/react-osm-geocoding/dist/index.css'
// import PropTypes from "prop-types";
import styled from 'styled-components';

import Map from 'ToolboxComponents/commons/map/map';

const SearchWrapper = styled.div`
  position: relative;
  padding-top:46px;

  #geocoding {
    width: 260px;
  }

  .places {
    z-index: 10000;
    position: absolute;
    width: 260px;
    margin-top: -46px;
  }
  .places > div:nth-child(1) {
    width: 260px;
  } 

`;

const Search = props => {
  const whiteLabel= useContext(WhiteLabelContext).state;
  const { tertiaryColor } = useContext(ThemeContext).state.color;
  const [hasLocationValue, setHasLocationValue] = useState(false);

  const {
    locationValue,
    coordinatesValue,
    handleSearch,
    handleCoordinates,
    handleCityCode,
    locationValid,
    hasClickedSubmit,
    userLocation,
    isProduct,
  } = props;

  useEffect(() => {
    if (
      locationValue !== '' &&
      locationValue !== null &&
      locationValue !== undefined &&
      !hasLocationValue
    ) {
      setHasLocationValue(true);
      document.getElementById('geocoding').value = locationValue;
      if (coordinatesValue && coordinatesValue.length > 0) {
        handleSelect({
          lat: coordinatesValue[0].latitude,
          lng: coordinatesValue[0].longitude,
        });
      }
    }
  }, [locationValue]);

  const handleSelect = async coordinates => {
    const cityCode = await getCityCode(coordinates);
    handleCityCode(cityCode);
    handleCoordinates({coordinates, isProduct: isProduct});
  };

  const getCityCode = async ({ lat, lng }) => {
    const data = await fetch(
      `https://geo.api.gouv.fr/communes?fields=nom,code,codeRegion&lon=${lng}&lat=${lat}`,
    );
    const response = await data.json();
    return response !== undefined ? response[0].code : null;
  };


  return (
    <SearchWrapper>
      <div className="places">
        <ReactOsmGeocoding className="geocoding-wrapper" placeholder="Saisissez un lieu ou une adresse" city={""} countrycode={"fr"} countrycodes={"fr"} acceptLanguage={"fr"} callback={data => {
          document.getElementById('geocoding').value = data.display_name;
          handleSearch(data.display_name);
          handleSelect({
            lat: data.lat,
            lng: data.lon
          });
        }}/>
      </div>
      <hr className={`separator`} />
      <Map
        className={`map`}
        height={`200px`}
        userLocation={userLocation}
        itemLocations={coordinatesValue}
        selected={0}
        zoom={16}
      />
    </SearchWrapper>
  );
};

// Search.propTypes = {};

export default Search;
