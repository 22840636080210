import React, { useContext } from 'react';
import { ThemeContext } from 'ToolboxUtils/web/context/context';
// import PropTypes from "prop-types";
import adjustLightness from 'ToolboxUtils/web/style/adjust-lightness';
import styled from 'styled-components';

const FirstSecretCardWrapper = styled.div`
  background: ${props => adjustLightness(props.theme.color.primaryColor, 30)};
  color: white;
  padding: 20px;
  height: 100%;

  .title {
    font-size: ${props => `${20*props.theme.font.primaryFont.scaling/100}px`};
    font-weight: ${props => parseInt(props.theme.font.primaryFont.weight)+300};
    margin-bottom: 10px;
  }
`;

const FirstSecretCard = props => {
  const theme = useContext(ThemeContext).state;
  return (
    <FirstSecretCardWrapper theme={theme}>
      <div className={`title`}>{props.title}</div>
      <div className={`content`}>{props.content}</div>
    </FirstSecretCardWrapper>
  );
};

// FirstSecretCard.propTypes = {};

export default FirstSecretCard;
