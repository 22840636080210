import React from 'react';
// import PropTypes from "prop-types";
import Slider from 'ToolboxComponents/commons/sliders/slider/slider/slider';
import FirstSecretCard from 'ToolboxComponents/commons/cards/first-secret-card/first-secret-card';
import styled from 'styled-components';

const FirstSecretWrapper = styled.div`
`;

const FirstSecret = props => {
  return (
    <FirstSecretWrapper className={`first-secret`}>
      <div className={`title`}>Vous allez chuchoter votre premier secret</div>
      <div className={`title title-light`}>Voici quelques conseils</div>
      <Slider
        className={`first-secret-slider`}
        mobile={{ dots: true }}
        desktop={{ dots: true }}
      >
        <FirstSecretCard
          title={`Un secret local, c'est quoi ?`}
          content={`Les secrets révèlent des bons plans méconnus, des astuces discrètes, des sites cachés… Comme par exemple un coin à pique-nique connu des seuls locaux, un spot photo à ne pas manquer, la spécialité d’un restaurant, le bon moment pour une visite…`}
        />
        <FirstSecretCard
          title={`Un secret local, c'est quoi ?`}
          content={`Un secret c'est bien plus qu'un avis ou une recommandation : c’est la place où il faut être au bon moment, c’est la bonne personne à rencontrer... Seuls les habitués – les normands comme vous – savent révéler les secrets les mieux gardés !`}
        />
      </Slider>
      <div className={`button button-first-secret`}>
        Merci pour les conseils, je suis prêt(e)
      </div>
    </FirstSecretWrapper>
  );
};

// FirstSecret.propTypes = {};

export default FirstSecret;
